import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ObjectToIdConversionService } from 'src/app/providers/object-to-id-conversion-service';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import {
  GeneralConfigurationControllerService,
  McpmssControllerService,
  ProposalControllerService,
  ProposalInfraConfigControllerService,
  ProposalPeripheralConfigControllerService,
  ProposalSaasConfigControllerService,
} from 'src/app/services/Billing/services';
import {
  AccountControllerService,
  BusinessProfileControllerService,
  TenantControllerService,
} from 'src/app/services/Platform/services';
import {
  APPROVE_ICON,
  CERTIFICATE_ICON,
  DELETE_IMAGE,
  DOWNLOAD_ICON,
  EDIT_IMAGE,
  GENERAL_INFO_ICON,
  INFRASTRUCTURE_ICON,
  PERIPHERAL_ICON,
  PROPOSAL_ICON,
  REJECT_ICON,
  SAAS_ICON,
  UPLOAD_ICON,
  VIEW_ICON,
  WORKFLOW_ICON,
  DOCUMENT_IMAGE,
  PAPER_CLIP,
  CLOUD_UPLOAD_ICON,
  CHEVRON_LEFT,
  INFO_ICON_IMAGE
} from 'src/app/shared/constants/strings';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit , TemplateRef} from '@angular/core';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener
} from '@angular/material/tree';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import { Router,ActivatedRoute } from '@angular/router';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { ShowHistoryDailogComponent } from 'src/app/shared/show-history-dialog/show-history-dialog.component';
import { ConfirmationDialogPopupComponent } from '../../shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { TranslationService } from 'src/app/providers/translation-service';
import { DatePipe } from '@angular/common';
import { BciLoaderService } from '@bci-web-core/core';
import { MatExpansionPanel } from '@angular/material/expansion';
interface SaasConfigMatTree {
  expandable: boolean;
  name: string;
  level: number;
}
@Component({
  selector: 'app-billing-configuration',
  templateUrl: './billing-configuration.component.html',
  styleUrls: ['./billing-configuration.component.scss']
})
export class BillingConfigurationComponent implements OnInit, AfterViewInit {
  public dropDownValues = [];
  public billingIcon: string;
  public infraJsonFileLoaded: string;
  public generalJsonFileLoaded: string;
  public peripheralJsonFileLoaded: string;
  public showSpinner :any;
  public pageKey: string;
  public showActionButtons = false;
  public columns: any[];
  public generalInfoImage;
  public infrastructureImage;
  public peripheralImage;
  public workflowImage;
  public proposalImage;
  public proposalId: string;
  public saasConfigImage;
  public approveImage;
  public rejectImage;
  public editImage;
  public uploadImage;
  public downloadImage;
  public documentImage;
  public attachmentImage;
  public cloudUploadImage;
  public deleteImage: string;
  public backImage;
  saasForm: UntypedFormGroup;
  public expandSaasPanel = false;
  public workFlowImage;
  public viewImage;
  public dataSource;
  public proposalList;
  public proposalDropdownList = [];
  public permissionList: Array<String> = [];
  public proposalDescription: string;
  public profileId: string;
  public ignoreList = [
    'id',
    'proposalInfrastructureConfigId',
    'proposalPeripheralConfigId',
    'proposalCreditId',
    'currencyType',
    'editable',
    'mssProposalStatus',
    'comments',
    'influencedSTP'
  ];
  public accountId: string;
  tenantId: string;
  public showGeneralPanel = false;
  showInfraPanel = false;
  showPeripheralPanel = false;
  public generalConfig;
  infraConfig;
  saasConfig;
  peripheralConfig;
  public disableSubmitButton = false;
  public version: string;
  public isSaasServicesLoaded = false;
  public status: string;
  public stpCheckbox = false;
  public proposalAction: string;
  public fileForUpload;
  public uploadedDocumentsList = [];
  public proposalComments: string;
  public proposalStatus: string;
  public submitted = false;
  public creditCurrency;
  public uploadedDocuments;
  public uploadedDocumentsIgnoreList = ['id','proposalId','url', 'createdDate', 'createdBy'];
  public uploadedDocumentsDataSource;
  public uploadedDocumentsColumns;
  public uploadedFile;
  public showAttachmentsPanel = false;
  public showTermsAndConditions = false;
  public termsAndConditionsControl: FormControl;
  public maxCharacterCount = 5000;
  public characterCount = 0;
  public saveTermsAndConditionsClicked = false;
  public isProposalEditable = false;
  public hasRouteData = false;
  public infoImage;
  allSassServicelist:any;


  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  @ViewChild('fileDropRef') fileDropRef: ElementRef;

  @ViewChild('generalConfigurationPanel') generalConfigurationPanel: MatExpansionPanel;
  @ViewChild('infraConfigurationPanel') infraConfigurationPanel: MatExpansionPanel;
  @ViewChild('saasConfigurationPanel') saasConfigurationPanel: MatExpansionPanel;
  @ViewChild('peripheralConfigurationPanel') peripheralConfigurationPanel: MatExpansionPanel;
  @ViewChild('attachmentsPanel') attachmentsPanel: MatExpansionPanel;
  @ViewChild('termsAndConditionsPanel') termsAndConditionsPanel: MatExpansionPanel;
  @ViewChild('saveGeneralConfigContent') saveGeneralConfigContent: TemplateRef<any>;


  public isFormSubmitted = false;
  public openedPanelName;
  public formArrayTableLength = 0;

  treeControl = new FlatTreeControl<SaasConfigMatTree>(
    (node) => node.level,
    (node) => node.expandable
  );
  private _transformer = (node, level: number) => {
    return {
      expandable: !!node.saaSServices && node.saaSServices.length > 0,
      name: node.name,
      level: level,
      productId: node.productId,
      skuId: node.skuId,
      defaultRatecard: node.defaultRatecard,
      displayCurrency:node.displayCurrency,
      rate:node.rate
    };
  };
  // tslint:disable-next-line
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.saaSServices
  );
  // tslint:disable-next-line
  saasServices = new MatTreeFlatDataSource(
    this.treeControl,
    this.treeFlattener
  );
  hasChild = (_: number, node: SaasConfigMatTree) => node.expandable;
  discountPercentageValue:any;
  currentProductId: any;
  region: string = 'IN';
  constructor(
    private tenantService: TenantControllerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private responseHandlerService: ResponseHandlerService,
    private accountControllerService: AccountControllerService,
    private route: ActivatedRoute,
    private generalConfigurationControllerService: GeneralConfigurationControllerService,
    private proposalInfraConfigControllerService: ProposalInfraConfigControllerService,
    private proposalPeripheralConfigControllerService: ProposalPeripheralConfigControllerService,
    private objectToIdConversionService: ObjectToIdConversionService,
    private proposalSaasConfigControllerService: ProposalSaasConfigControllerService,
    private formBuilder: UntypedFormBuilder,
    private proposalControllerService: ProposalControllerService,
    private httpService: HttpClient,private translationService: TranslationService,
    private _matDialog: MatDialog,
    private datePipe: DatePipe,private loaderService: BciLoaderService,
    private router:Router,
    private mcpmssControllerService: McpmssControllerService
  ) {}

  ngOnInit() {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.billingIcon = CERTIFICATE_ICON;
    this.backImage = CHEVRON_LEFT;
    this.generalInfoImage = GENERAL_INFO_ICON;
    this.infrastructureImage = INFRASTRUCTURE_ICON;
    this.peripheralImage = PERIPHERAL_ICON;
    this.saasConfigImage = SAAS_ICON;
    this.approveImage = APPROVE_ICON;
    this.rejectImage = REJECT_ICON;
    this.editImage = EDIT_IMAGE;
    this.deleteImage = DELETE_IMAGE;
    this.infraJsonFileLoaded = 'infra-configuration';
    this.generalJsonFileLoaded = 'general-configuration';
    this.peripheralJsonFileLoaded = 'peripheral-configuration';
    this.saasForm = this.formBuilder.group({});
    this.workFlowImage = WORKFLOW_ICON;
    this.proposalImage = PROPOSAL_ICON;
    this.viewImage = VIEW_ICON;
    this.uploadImage = UPLOAD_ICON;
    this.downloadImage = DOWNLOAD_ICON;
    this.documentImage = DOCUMENT_IMAGE;
    this.attachmentImage = PAPER_CLIP;
    this.cloudUploadImage = CLOUD_UPLOAD_ICON;
    this.infoImage = INFO_ICON_IMAGE;
    this.route.queryParams.subscribe(params => {
      if (params.proposal) {
        this.hasRouteData = true;
        const data = JSON.parse(params.proposal);
        this.accountId = data['accountId'];
        this.tenantId = data['tenantId'];
        this.profileId = data['profileId'];
        this.waitForGeneralConfigPanel().then((panel) => {
          this.getConfigurations(data, this.generalConfigurationPanel);
          this.getTenant(this.accountId);
          this.getProfile(this.tenantId);
          if(this.status !== 'DRAFT')
          {
            this.displaySaveToCreateNewProposal();
          }
        });
      }
    });
  }

  ngAfterViewInit() {
    this.generalConfigurationPanel.afterExpand.subscribe(() => {
      this.disableAllPanelsExcept(this.generalConfigurationPanel);
    });

    this.infraConfigurationPanel.afterExpand.subscribe(() => {
      this.disableAllPanelsExcept(this.infraConfigurationPanel);
    });

    this.peripheralConfigurationPanel.afterExpand.subscribe(() => {
      this.disableAllPanelsExcept(this.peripheralConfigurationPanel);
    });

    this.termsAndConditionsPanel.afterExpand.subscribe(() => {
      this.disableAllPanelsExcept(this.termsAndConditionsPanel);
    });

    this.saasConfigurationPanel.afterExpand.subscribe(() => {
      this.disableAllPanelsExcept(this.saasConfigurationPanel);
    });

  }




  /**
   * @ngdoc method
   * @name billingConfiguration#updateDropdownValues
   *
   * @methodOf
   * device.controller:createDevice
   *
   * @description
   * Description: To populate dependent dropdown.
   *
   * @param {type} dropdown select event
   * @return {type} null
   */
  public updateDropdownValues($event, dropdownSelected?) {
    if ($event.value !== undefined) {
      if (dropdownSelected) {
        if (dropdownSelected === 'tenant') {
          this.getTenant($event.value);
          this.accountId = $event.value;
          this.resetForms(); // reset all configuration forms when user selects account from proposal panel
        }
        if (dropdownSelected === 'businessProfile') {
          this.getProfile($event.value);
          this.tenantId = $event.value;
        }
        if (dropdownSelected === 'proposal') {
          this.getProposalList($event.value);
        }
      } else {
        if ($event.child === 'tenant') {
          this.getTenant($event.value);
          this.accountId = $event.value;
        }
        if ($event.child === 'businessProfile') {
          this.getProfile($event.value);
          this.tenantId = $event.value;
        }
        if($event.child === 'setCurrency') {
          this.creditCurrency = $event.value;
          this.setCurrency($event.value);
        }
        if ($event.child === 'customerId') {
          this.setRegion($event.value);
          this.getPartnerCustomerId($event.value);
        }
        if (
          $event.child['length'] &&
          this.dropDownValues['cloudProvider'] !== undefined
        ) {
          this.dropDownValues['infraService'].forEach((infra) => {
            if (infra['id'] === $event.value) {
              if (infra.name.includes('MongoDB') || infra.name.includes('GCP') || infra.name.includes('AWS - Marketplace')) {
                this.updateInfraConfigValidation('remove');
              } else {
                this.updateInfraConfigValidation();
                this.dropDownValues['cloudProvider'].forEach(
                  (cloudProvider) => {
                    if (cloudProvider['id'] === infra['cloudTypeId']) {
                      if ($event.child.includes('offerType')) {
                        this.dropDownValues['offerType'] =
                          cloudProvider['offerTypes'];
                      }
                      if ($event.child.includes('reservationType')) {
                        this.dropDownValues['reservationType'] =
                          cloudProvider['reservationTypes'];
                      }
                    }
                  }
                );
              }
              if (infra.name.includes('AWS')) {
                this.updateMarketplace('enable');
              } else {
                this.updateMarketplace();
              }
            }
          });
        }
        if ($event.child === 'supportType') {
          this.dropDownValues['peripheralService'].forEach(
            (peripheralService) => {
              if (peripheralService['id'] === $event.value) {
                this.dropDownValues['supportType'] =
                  peripheralService['supportTypes'];
              }
            }
          );
        }
      }
    } else {
      if ($event.child === 'supportType') {
        this.dropDownValues['supportType'] = [];
      }
    }
  }

  public setRegion(selectedId: string): void {
    const selectedItem =  this.dropDownValues['channelPartner'].find((item) => item.id === selectedId);
    if (selectedItem && selectedItem.name.includes('EU')) {
      this.region = 'EU'; 
    } else if (selectedItem && selectedItem.name.includes('NA')) {
      this.region = 'NA'; 
    } else {
      this.region = 'IN'; 
    }
  }

  public setCurrency(currencyType) {
    const currency = this.dynamicForm.createForm?.controls?.proposedCredit as FormArray;
    currency.controls?.forEach((control) => {
      (control as FormGroup).controls['creditCurrency'].setValue(currencyType);
    });
  }
  
  public onFormArrayReset(event) {
    if(event?.message === 'FormArray Reset' && this.creditCurrency) {
      this.setCurrency(this.creditCurrency);
    }
 }

  public getAccount() {
    this.accountControllerService.findAllAccounts({ active: 'true' }).subscribe(
      (resp) => {
        if (resp && resp['length']) {
          this.dropDownValues['account'] = resp;
        } else {
          this.dropDownValues['account'] = [];
        }
      },
      (err) => {
        this.dropDownValues['account'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getTenant(accountId: string) {
    this.tenantService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        (err) => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public configureInfraConfiguration(data, saasConfigurationPanel) {
    if (this.status === 'DRAFT') {
      this.updateInfraConfiguration(data, saasConfigurationPanel);
      return;
    }
    this.showSpinner = this.loaderService.showProgressBar();
    if (!data['table']) {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage(
        'error',
        'PLEASE_ADD_THE_INFRASTRUCTURE_CONFIGURATIONS'
      );
      return;
    }
    data['table'].forEach((config) => {
      config['infraService'] =
        this.objectToIdConversionService.convertObjectToId(
          config['infraService']
        );
      config['offerType'] = config['offerType']
        ? this.objectToIdConversionService.convertObjectToId(
            config['offerType']
          )
        : { id: null };
      config['reservationType'] = config['reservationType']
        ? this.objectToIdConversionService.convertObjectToId(
            config['reservationType']
          )
        : { id: null };
    });
    this.proposalInfraConfigControllerService
      .saveProposalInfraConfig({
        body: { proposalId: this.proposalId, infraConfig: data['table'] },
      })
      .subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'INFRASTRUCTURE_CONFIGURATION_CREATE_SUCCESS'
          );
          this.proposalId = resp['proposalId']
            ? resp['proposalId']
            : this.proposalId;
            this.isFormSubmitted = true;
          saasConfigurationPanel.open();
          this.onAccordionExpansion('saas-configuration');
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public onAccordionExpansion(index) {
    if(!this.openedPanelName || this.openedPanelName !== index) {
      this.openedPanelName = index;
      this.checkAccordion(index);
    setTimeout(() => {
      this.checkToDisableForm();
    }, 500);
    }
    this.formArrayTableLength = 0;
}

public onAccordionClicked(panel, panelName) {
  if(!panel?.expanded) {
    if(this.openedPanelName !== panelName) {
      if(this.openedPanelName === 'termsAndCondition') {
        if(this.termsAndConditionsControl?.dirty && !this.isFormSubmitted) {
          this.dataNotSavedConfirmation(panel,panelName);
        } else {
          panel.disabled = false;
          panel.open();
        }
      } else if(this.openedPanelName === 'saas-configuration') {
        if(this.saasForm?.dirty && !this.isFormSubmitted) {
          this.dataNotSavedConfirmation(panel,panelName);
        } else {
          panel.disabled = false;
          panel.open();
        }
      } else if(this.openedPanelName === 'attachments') {
        panel.disabled = false;
        panel.open();
      } else {
        this.waitForDynamicForm().then((dynamicForm) => {
          if((this.dynamicForm?.createForm?.dirty || this.formArrayTableLength !==  this.dynamicForm.dataSource?.length) &&!this.isFormSubmitted) {
            this.dataNotSavedConfirmation(panel,panelName);
          } else {
            panel.disabled = false;
            panel.open();
          }
        });
      }

    }  
  }
}

public waitForDynamicForm() {
  return new Promise((resolve) => {
    const intervalId = setInterval(() => {
      if (this.dynamicForm?.createForm) {
        clearInterval(intervalId);
        resolve(this.dynamicForm.createForm);
      }
    }, 100);
  });
}

public waitForGeneralConfigPanel() {
  return new Promise((resolve) => {
    const intervalId = setInterval(() => {
      if (this.generalConfigurationPanel) {
        clearInterval(intervalId);
        resolve(true);
      }
    }, 100);
  });
}

public checkAccordion(index) {
  switch (index) {
    case 'infra-configuration':
      this.getInfraServices();
      this.getCloudProviders();
      this.getInfraConfig();
      break;
    case 'general-configuration':
      this.getAccount();
      this.getRevenueTypes();
      this.getChannelPartner();
      this.getCurrencyTypes();
      this.getGeneralConfiguration();
      break;
    case 'peripheral-configuration':
      this.getPeripheralServices();
      this.getPeripheralConfig();
      break;
    case 'attachments' :
      this.getAttachments(); 
      break;

    case 'termsAndCondition' :
      this.getTermsAndConditions(); 
      break;
    case 'saas-configuration':
        this.getSaasServices().then((value) => {
          this.getSaasConfigs();
        });
      break;
    case 'proposal':
      this.loadProposalDropDowns();
      break;
  }
}

public dataNotSavedConfirmation(panel,index) {
  const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
    maxWidth: '500px',
    disableClose: true, data: { message: this.translationService.translateErrorMessages('DATA_NOT_SAVED') }
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      this.isFormSubmitted = false;
      panel.disabled = false;
      panel.open();
    } else {
      this._matDialog.closeAll();
    }
  });
}

public disableAllPanelsExcept(exceptionPanel) {
  const allPanels = [
    this.generalConfigurationPanel,
    this.infraConfigurationPanel,
    this.peripheralConfigurationPanel,
    this.termsAndConditionsPanel,
    this.saasConfigurationPanel,
    this.attachmentsPanel
  ];

  for (const panel of allPanels) {
    if (panel !== exceptionPanel) {
      panel.disabled = true;
    }
  }
}
  public loadProposalDropDowns() {
    if(this.profileId) {
      this.getProposalList(this.profileId);
    }
    if (!this.proposalDropdownList.length) {
      this.httpService
        .get('./assets/form-fields/proposals.json')
        .subscribe((data) => {
          this.proposalDropdownList = data as string[];
          this.proposalDropdownList = this.proposalDropdownList['fields'];
        });
      this.getAccount();
    }
  }

  public getInfraServices() {
    this.proposalInfraConfigControllerService.getAllInfraServicesByRegion({ region: this.region}).subscribe(
      (resp) => {
        if (resp && resp.length) {
          const infraService: any[] = [];
          resp.forEach((infra) => {
            infraService.push({
              id: infra['infraServiceId'],
              name: infra['displayName'],
              cloudTypeId: infra['cloudType']['id'],
            });
          });
          this.dropDownValues['infraService'] = infraService;
        } else {
          this.dropDownValues['infraService'] = [];
        }
      },
      (err) => {
        this.dropDownValues['infraService'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }
  public getCloudProviders() {
    // cloudTypeId parameter to be updated later
    this.proposalInfraConfigControllerService
      .getAllCloudTypes({ cloudTypeId: '' })
      .subscribe(
        (resp) => {
          if (resp && resp['length']) {
            this.dropDownValues['cloudProvider'] = resp;
          } else {
            this.dropDownValues['cloudProvider'] = [];
          }
        },
        (err) => {
          this.dropDownValues['cloudProvider'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getCurrencyTypes() {
    this.generalConfigurationControllerService.findAllCurrencyTypes().subscribe(
      (resp) => {
        if (resp && resp.length) {
          this.dropDownValues['targetCurrency'] = resp;
          this.dropDownValues['creditCurrency'] = resp;
        } else {
          this.dropDownValues['targetCurrency'] = [];
          this.dropDownValues['creditCurrency'] = [];
        }
      },
      (err) => {
        this.dropDownValues['targetCurrency'] = [];
        this.dropDownValues['creditCurrency'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }


  public getRevenueTypes() {
    this.generalConfigurationControllerService.findAllRevenueTypes().subscribe(
      (resp) => {
        if (resp && resp.length) {
          this.dropDownValues['revenueType'] = resp;
        } else {
          this.dropDownValues['revenueType'] = [];
        }
      },
      (err) => {
        this.dropDownValues['revenueType'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getChannelPartner() {
    this.generalConfigurationControllerService
      .findAllChannelPartners()
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['channelPartner'] = resp;
          } else {
            this.dropDownValues['channelPartner'] = [];
          }
        },
        (err) => {
          this.dropDownValues['channelPartner'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }


  public createGeneralConfiguration(data: any, infraConfigurationPanel) {
    let addButtonValidation;
     this.dynamicForm.createForm.value.proposedCredit.forEach(data =>{
        if( data.credit||data.creditCap||  data.credit || data.fromDate){
          addButtonValidation = true;
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error','PLEASE_CLICK_ON_ADD_BUTTON_TO_ADD_THE_CONFIGURATIONS');
        }
      });
      if (addButtonValidation) {
         return null;
        }  
    this.stpCheckbox =
      this.dropDownValues['revenueType'].find(
        (element) => element.id === data.revenueType
      ).name === 'STP';
    if (this.status === 'DRAFT') {
      this.updateGeneralConfiguration(data, infraConfigurationPanel);
      return;
    }
    this.showSpinner = this.loaderService.showProgressBar();
    const creditInfos: any[] = [];
    if (data['table'] !== undefined) {
      data['table'].forEach((row) => {
        creditInfos.push({
          currencyType: this.objectToIdConversionService.convertObjectToId(
            row['creditCurrency']
          ),
          credit: parseInt(row['credit'], 10),
          fromDate: row['fromDate'],
          toDate: row['toDate'],
          creditCap: parseFloat(row['creditCap']),
        });
      });
    }
    const bodyJSON = {
      profileId: data['businessProfile'],
      revenueType: data['revenueType'],
      channelPartner: data['channelPartner'],
      currencyType: data['targetCurrency'],
      customerId: data['customerId'],
      sendToMSS: data['sendToMSS'],
      creditInfos: creditInfos.length ? creditInfos : null,
      proposalId: this.proposalId ? this.proposalId : null,
    };
    this.generalConfigurationControllerService
      .saveProposalGeneralDetails({ body: bodyJSON })
      .subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'GENERAL_CONFIGURATION_CREATE_SUCCESS'
          );
          this.proposalId = resp['proposalId'];
          this.status = resp['status'];
          this.isProposalEditable = true;
          this.profileId = data['businessProfile'];
          this.version = resp['version'].toString();
          this.isFormSubmitted = true;
          infraConfigurationPanel.open();
          this.onAccordionExpansion('infra-configuration');
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getPeripheralServices() {
    this.proposalPeripheralConfigControllerService
      .getAllPeripheralServices()
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            resp.forEach((peripheral) => {
              peripheral['name'] = peripheral['displayName'];
            });
            this.dropDownValues['peripheralService'] = resp;
          } else {
            this.dropDownValues['peripheralService'] = [];
          }
        },
        (err) => {
          this.dropDownValues['peripheralService'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public configurePeripheralConfiguration(data: any, attachmentPanel) {
    if (this.status === 'DRAFT') {
      this.updatePeripheralConfiguration(data, attachmentPanel);
      return;
    }
    this.showSpinner = this.loaderService.showProgressBar();
    if (!data['table']) {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage(
        'error',
        'PLEASE_ADD_THE_PERIPHERAL_CONFIGURATIONS'
      );
      return;
    }
    data['table'].forEach((config) => {
      config['supportType'] =
        this.objectToIdConversionService.convertObjectToId(
          config['peripheralService']
        );
      config['discountPercentage'] = config['discountPercentage']
        ? parseInt(config['discountPercentage'], 10)
        : 0;
    });
    this.proposalPeripheralConfigControllerService
      .saveProposalPeripheralConfig({
        body: { proposalId: this.proposalId, peripheralConfigs: data['table'] },
      })
      .subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'PERIPHERAL_CONFIGURATION_CREATE_SUCCESS'
          );
          this.proposalId = resp['proposalId']
            ? resp['proposalId']
            : this.proposalId;
            this.isFormSubmitted = true;
            attachmentPanel.open();
            this.onAccordionExpansion('attachments');
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getSaasServices(): Promise<any> {
    return new Promise((resolve, reject) => {
    this.setPanel('saas');
    if (this.isSaasServicesLoaded) {
      resolve(true);
      return;
    }
    this.proposalSaasConfigControllerService.getAllSaaSService().subscribe(
      (resp) => {
        const saasServices = resp;
        this.allSassServicelist = resp;
        saasServices.forEach((saasService) => {
          const services = saasService['saaSServices'];
          services.forEach((service) => {
            this.saasForm.addControl(
              service.skuId,
              this.formBuilder.control('')
            );
            this.saasForm.addControl(service.skuId + '_discountPercentage',this.formBuilder.control(''));
            this.saasForm.controls[service.skuId + '_discountPercentage'].disable();
          });
        });
        this.saasServices.data = saasServices;
        this.isSaasServicesLoaded = true;
        resolve(true);
      },
      (err) => {
        resolve(false);
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
    });
  }

  public saveSaasConfig(peripheralConfigurationPanel) {
    const form = this.saasForm.value;
    let validation = true;
    if (this.status === 'DRAFT') {
      Object.keys(form).forEach((saasId) => {
        if(form[saasId]==true && form[saasId + '_discountPercentage']!==undefined ){
          if(form[saasId + '_discountPercentage']==='' || form[saasId + '_discountPercentage']==null){
            validation =false;
          const mandatory:any = document.getElementsByClassName(saasId+'id');
         [...mandatory].forEach(element => {
           element.style.display='initial';
          });
          }
        }
      });
      if(!validation){
        this.treeControl.expandAll();
        this.responseHandlerService.returnToastMessage(
          'error',
          'PLEASE_ENTER_MANDATORY_FIELDS'
        );
        this.loaderService.hideProgressBar(this.showSpinner);
        return ;
      }
      this.updateSaasConfig(peripheralConfigurationPanel);
      return;
    }
    this.showSpinner = this.loaderService.showProgressBar();
    const saasConfigs = [];
    Object.keys(form).forEach((saasId) => {
      if(form[saasId]==true && form[saasId + '_discountPercentage']!==undefined ){
        if(form[saasId + '_discountPercentage']==='' || form[saasId + '_discountPercentage']==null){
          validation =false;
        const mandatory:any = document.getElementsByClassName(saasId+'id');
       [...mandatory].forEach(element => {
         element.style.display='initial';
        });
        }
      }
     
       if (form[saasId]) {
        saasConfigs.push({
          saasId: saasId,
          discountPercentage: form[saasId + '_discountPercentage'],
        });
      }
    });
    
    if(!validation){
      this.treeControl.expandAll();
      this.responseHandlerService.returnToastMessage(
        'error',
        'PLEASE_ENTER_MANDATORY_FIELDS'
      );
      this.loaderService.hideProgressBar(this.showSpinner);
      return ;
    }

    if (saasConfigs.length === 0) {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage(
        'error',
        'PLEASE_SELECT_SAAS_CONFIGURATION'
      );
      return;
    }
    this.proposalSaasConfigControllerService
      .saveProposalSaasConfig({
        body: { proposalId: this.proposalId, saasConfigs: saasConfigs },
      })
      .subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'SAAS_CONFIGURATION_CREATE_SUCCESS'
          );
          this.proposalId = resp['proposalId']
            ? resp['proposalId']
            : this.proposalId;
            this.isFormSubmitted = true;
          peripheralConfigurationPanel.open();
          this.onAccordionExpansion('peripheral-configuration');
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error[0].message
          );
        }
      );
  }

  public submitProposal() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.proposalControllerService
      .submitRecallProposal({
        body: {
          proposalId: this.proposalId,
          status: true,
          comments: this.proposalComments,
        },
      })
      .subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'PROPOSAL_SUBMIT_SUCCESS'
          );
          this.isProposalEditable = false;
          this.proposalComments = '';
          this.navigateBack();
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
    this.onClose();
  }

  public getProposalList(profileId: string) {
    this.profileId = profileId;
    this.showSpinner = this.loaderService.showProgressBar();
    this.proposalControllerService
      .getAllProposals({ profileId: profileId })
      .subscribe(
        (resp) => {
          if (resp && resp['length']) {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.proposalList = resp;
            this.proposalList.forEach((proposal) => {
              proposal['revenueType'] =
                proposal['revenueType'] && proposal['revenueType']['name'];
            });
            this.dataSource = new MatTableDataSource<any>(this.proposalList);
            for (const data of this.proposalList) {
              data['createdDt'] = this.datePipe.transform(data['createdDt'], 'd-M-yyyy h:mm a');
              data['approvedDt'] = this.datePipe.transform(data['approvedDt'], 'd-M-yyyy h:mm a');
              data['action'] = null;
            }
            this.columns = this.filterColumns(
              Object.keys(this.proposalList[0])
            );
            this.proposalStatus = this.proposalList[0]['status'];
          } else {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'warning',
              'NO_DATA_AVAILABLE'
            );
          }
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
          this.proposalList = null;
        }
      );
  }

  public checkPermission(key: string) {
    if (this.permissionList.indexOf(key) <= -1) {
      return false;
    }
    return true;
  }

  public gotoProposalApproveRejectDialog(tableRow, content, action) {
    this.proposalDescription = null;
    this.proposalAction = action;
    if (tableRow !== undefined) {
      this.proposalId = tableRow['id'];
    }
    this._matDialog.open(content, {
      maxHeight: '80vh',
      minWidth: '30vw',
      disableClose: true,
      data: null,
    });
  }

  public gotoSubmitProposalDialog(content,proposalId = this.proposalId) {
    if(proposalId) {
      this.proposalId = proposalId;
    }

    if(!this.proposalId) {
      return;
    }
    this._matDialog.open(content, {
      maxHeight: '80vh',
      minWidth: '30vw',
      disableClose: true,
      data: null,
    });
  }

  public approveRejectProposal(tableRow) {
    if (!this.proposalDescription && this.proposalAction === 'REJECT') {
      this.responseHandlerService.returnToastMessage(
        'error',
        'REASON_IS_MANDATORY'
      );
      return;
    }
    if (tableRow !== undefined) {
      this.proposalId = tableRow['id'];
    }
    const proposalStatus = this.proposalAction === 'APPROVE';
    const description = !proposalStatus ? this.proposalDescription : null;
    const bodyJSON = {
      proposalId: this.proposalId,
      status: proposalStatus,
      description: description,
    };
    this.onClose();
    this.proposalControllerService
      .approveRejectProposals({ body: bodyJSON })
      .subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            proposalStatus
              ? 'PROPOSAL_APPROVE_SUCCESS'
              : 'PROPOSAL_REJECT_SUCCESS'
          );
          if (this.profileId) {
            this.getProposalList(this.profileId);
          }
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getConfigurations(data: any, generalConfigurationPanel) {
    // sales admin(edit proposal) and business admin(view proposal) initiates here
    this.proposalId = data['id'];
    this.version = data['version'];
    this.status = data['status'];
    this.isProposalEditable = data['editable'];
    this.stpCheckbox = data['revenueType'] === 'STP';
    this.onAccordionExpansion('general-configuration');
    generalConfigurationPanel.open();
  }

  setPanel(panel) {
    this.showGeneralPanel = false;
    this.showInfraPanel = false;
    this.showPeripheralPanel = false;
    this.showAttachmentsPanel = false;
    this.showTermsAndConditions = false;
    switch (panel) {
      case 'general':
        this.showGeneralPanel = true;
        break;
      case 'infra':
        this.showInfraPanel = true;
        break;
      case 'peripheral':
        this.showPeripheralPanel = true;
        break;
      case 'attachments' :
        this.showAttachmentsPanel = true;
        break;
     case 'termsAndConditions' :
        this.showTermsAndConditions = true;
        break;
    }
  }

  getGeneralConfiguration() {
    this.setPanel('general');
    if (!this.profileId) {
      return;
    }
    if (
      this.proposalStatus === 'SUBMITTED' &&
      this.checkPermission('PROPSL_C')
    ) {
      return;
    }
    this.generalConfigurationControllerService
      .getProposalGeneralDetails({
        profileId: this.profileId,
        version: this.version,
      })
      .subscribe(
        (resp) => {
          if (resp) {
            this.status = resp['status'] ? resp['status'] : this.status;
            this.generalConfig = resp;
            this.generalConfig['account'] = this.accountId;
            this.generalConfig['tenant'] = this.tenantId;
            this.generalConfig['businessProfile'] = resp['profileId'];
            this.generalConfig['targetCurrency'] = resp['currencyType'];
            if(resp['currencyType']) {
              this.creditCurrency = resp['currencyType'];
              this.setCurrency(this.creditCurrency);
            }
            this.setRegion(this.generalConfig['channelPartner']);
            this.dynamicForm.createForm.patchValue(this.generalConfig);

            const credits = [];
            const creditsWithIds = [];
            const creditInfos = resp['creditInfos'] as any[];
            if (creditInfos.length > 0) {
              creditInfos.forEach((row) => {
                credits.push({
                  proposalCreditId: row['proposalCreditId'],
                  currencyType: row['currencyType'],
                  creditCurrency:
                    this.objectToIdConversionService.convertObjectToName(
                      row['currencyType']
                    ),
                  credit: row['credit'],
                  fromDate: row['fromDate'],
                  toDate: row['toDate'],
                  creditCap: row['creditCap'],
                });
                creditsWithIds.push({
                  proposalCreditId: row['proposalCreditId'],
                  currencyType: row['currencyType'],
                  creditCurrency:
                    this.objectToIdConversionService.convertIdToObject(
                      row['currencyType']
                    ),
                  credit: row['credit'],
                  fromDate: row['fromDate'],
                  toDate: row['toDate'],
                  creditCap: row['creditCap'],
                });
              });
              this.dynamicForm.createForm.value.table = credits;
              this.dynamicForm.dataSource = credits;
              this.formArrayTableLength = credits?.length?credits.length:0;
              this.dynamicForm.dataSourceWithIds = creditsWithIds;
              this.dynamicForm.columns = this.filterColumns(
                Object.keys(credits[0])
              );
              this.addActionColumn();
            }
            this.checkToDisableForm();
          }
        },
        (err) => {
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
          this.checkToDisableForm();
        }
      );
  }

  getInfraConfig() {
    this.setPanel('infra');
    if (!this.proposalId) {
      return;
    }
    this.proposalInfraConfigControllerService
      .getProposalInfraConfig({ proposalId: this.proposalId })
      .subscribe(
        (resp) => {
          if (resp) {
            this.status = resp['status'] ? resp['status'] : this.status;
            const configs = [];
            const configWithIds = [];
            this.infraConfig = resp['infraConfig'] as any[];
            this.infraConfig.forEach((config) => {
              configs.push({
                proposalInfrastructureConfigId:
                  config['proposalInfrastructureConfigId'],
                customName: config['customName'],
                infraService:
                  this.objectToIdConversionService.convertObjectToName(
                    config['infraService']
                  ),
                offerType: this.objectToIdConversionService.convertObjectToName(
                  config['offerType']
                ),
                reservationType:
                  this.objectToIdConversionService.convertObjectToName(
                    config['reservationType']
                  ),
                discountPercentage: config['discountPercentage'],
                marketplace: config['marketplace'],
              });
              configWithIds.push({
                proposalInfrastructureConfigId:
                  config['proposalInfrastructureConfigId'],
                customName: config['customName'],
                infraService:
                  this.objectToIdConversionService.convertIdToObject(
                    config['infraService']
                  ),
                offerType: this.objectToIdConversionService.convertIdToObject(
                  config['offerType']
                ),
                reservationType:
                  this.objectToIdConversionService.convertIdToObject(
                    config['reservationType']
                  ),
                discountPercentage: config['discountPercentage'],
                marketplace: config['marketplace'],
              });
            });
            this.formArrayTableLength = configs?.length?configs.length:0;
            this.dynamicForm.dataSource = configs;
            this.dynamicForm.dataSourceWithIds = configWithIds;
            this.dynamicForm.columns = this.filterColumns(
              Object.keys(configs[0])
            );
            this.addActionColumn();
            this.checkToDisableForm();
          }
        },
        (err) => {
          this.checkToDisableForm();
        }
      );
  }

  getPeripheralConfig() {
    this.setPanel('peripheral');
    if (!this.proposalId) {
      return;
    }
    this.proposalPeripheralConfigControllerService
      .getAllByProposalId({ proposalId: this.proposalId })
      .subscribe(
        (resp) => {
          if (resp) {
            this.status = resp['status'] ? resp['status'] : this.status;
            const configs = [];
            const configWithIds = [];
            this.peripheralConfig = resp['peripheralConfigs'];
            if (this.peripheralConfig.length) {
              this.peripheralConfig.forEach((config) => {
                configs.push({
                  proposalPeripheralConfigId:
                    config['proposalPeripheralConfigId'],
                  peripheralService:
                    this.objectToIdConversionService.convertObjectToName(
                      config['supportType']
                    ),
                  discountPercentage: config['discountPercentage'],
                });
                configWithIds.push({
                  proposalPeripheralConfigId:
                    config['proposalPeripheralConfigId'],
                  peripheralService:
                    this.objectToIdConversionService.convertIdToObject(
                      config['supportType']
                    ),
                  discountPercentage: config['discountPercentage'],
                });
              });
              this.formArrayTableLength = configs?.length?configs.length:0;
              this.dynamicForm.dataSource = configs;
              this.dynamicForm.dataSourceWithIds = configWithIds;
              this.dynamicForm.columns = this.filterColumns(
                Object.keys(configs[0])
              );
              this.addActionColumn();
            }
            this.checkToDisableForm();
          }
        },
        (err) => {
          this.checkToDisableForm();
        }
      );
  }

  getSaasConfigs() {
    if (!this.proposalId) {
      return;
    }
    this.proposalSaasConfigControllerService
      .findByProposalId({ proposalId: this.proposalId })
      .subscribe(
        (resp) => {
          if (resp) {
            this.status = resp['status'] ? resp['status'] : this.status;
            this.saasForm.reset();
            this.saasConfig = resp['saasConfigs'];
            if (this.saasConfig.length) {
              this.treeControl.expandAll();
              this.saasConfig.forEach((config,index) => {
                setTimeout(() => {
                  this.saasForm.get(config.saasId).setValue(true);
                  this.saasForm.get(config.saasId + '_discountPercentage').setValue(config['discountPercentage']);
                  this.saasForm.controls[config.saasId + '_discountPercentage'].enable();               
                }, 900);
              });
            }
            this.checkToDisableForm();
          }
        },
        (err) => {
          this.checkToDisableForm();
        }
      );
  }

  public updateGeneralConfiguration(data: any, infraConfigurationPanel) {
    this.showSpinner = this.loaderService.showProgressBar();
    let creditInfos = [];
    if (data['table'] !== undefined) {
      const newCredits = data['table'] as any[];
      newCredits.forEach((newCredit) => {
        creditInfos.push({
          proposalCreditId: newCredit['proposalCreditId'],
          credit: parseInt(newCredit['credit'], 10),
          currencyType: this.objectToIdConversionService.convertObjectToId(
            newCredit['currencyType']
              ? newCredit['currencyType']
              : newCredit['creditCurrency']
          ),
          fromDate: newCredit['fromDate'],
          toDate: newCredit['toDate'],
          creditCap: parseFloat(newCredit['creditCap']),
          action: !newCredit['proposalCreditId']
            ? 'CREATED'
            : newCredit['action']
            ? newCredit['action']
            : null,
        });
      });
    }
    const deletedCredits = this.dynamicForm.dataSourceDeletedRows;
    if (deletedCredits.length) {
      creditInfos = creditInfos.concat(deletedCredits);
    }
    const bodyJSON = {
      proposalId: this.proposalId,
      profileId: data['businessProfile'],
      revenueType: data['revenueType'],
      channelPartner: data['channelPartner'],
      currencyType: data['targetCurrency'],
      customerId: data['customerId'],
      sendToMSS: data['sendToMSS'],
      creditInfos: creditInfos.length ? creditInfos : null,
      status: this.generalConfig['status'],
      version: this.generalConfig['version'],
    };
    this.generalConfigurationControllerService
      .updateProposalGeneralDetails({ body: bodyJSON })
      .subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'GENERAL_CONFIGURATION_UPDATE_SUCCESS'
          );
          this.proposalId = resp['proposalId'];
          infraConfigurationPanel.open();
          this.onAccordionExpansion('infra-configuration');
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public updateSaasConfig(peripheralConfigurationPanel) {
    // If the service checkbox is checked, action would be CREATED. If it's unchecked, action would be DELETED otherwise null
    this.showSpinner = this.loaderService.showProgressBar();
    const saasConfigs = [];
    const saasForm = this.saasForm.value;
    if (this.saasConfig) {
      // Update the config when config is already created
      Object.keys(saasForm).forEach((saasId) => {
        let isConfigDeleted = false;
        this.saasConfig.forEach((config) => {
          if (config.saasId === saasId) {
            isConfigDeleted = true;
            saasConfigs.push({
              proposalSaasConfigId: config.proposalSaasConfigId,
              saasId: saasId,
              discountPercentage: !saasForm[saasId]? 0: saasForm[saasId + '_discountPercentage'],
              action: !saasForm[saasId] ? 'DELETED' : 'UPDATED',
            });
          }
        });
        if (saasForm[saasId] === true && !isConfigDeleted) {
          saasConfigs.push({
            saasId: saasId,
            discountPercentage: saasForm[saasId + '_discountPercentage'],
            action: 'CREATED',
          });
        }
      });
    } else {
      // Create the config for the first time
      Object.keys(saasForm).forEach((saasId) => {
        if (saasForm[saasId] && saasForm[saasId] === true) {
          saasConfigs.push({
            saasId: saasId,
            discountPercentage: saasForm[saasId + '_discountPercentage'],
            action: 'CREATED',
          });
        }
      });
    }
    this.proposalSaasConfigControllerService
      .saveProposalSaasConfig({
        body: { proposalId: this.proposalId, saasConfigs: saasConfigs },
      })
      .subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'SAAS_CONFIGURATION_UPDATE_SUCCESS'
          );
          peripheralConfigurationPanel.open();
          this.onAccordionExpansion('peripheral-configuration');
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public updateInfraConfiguration(data: any, saasPanel) {
    let addButtonValidation;
    this.dynamicForm.createForm.value.infraConfig.forEach(data =>{
      if( data.customName||data.infraService|| data.offerType|| data.reservationType){
        addButtonValidation = true;
        this.responseHandlerService.returnToastMessage('error','PLEASE_ADD_THE_INFRASTRUCTURE_CONFIGURATIONS');      
      }
    });
    if (addButtonValidation) {
      return null;
     } 
   this.showSpinner = this.loaderService.showProgressBar();
   const configs = [];
    const deletedRows = this.dynamicForm.dataSourceDeletedRows;
    if (deletedRows.length) {
      data['table'] = data['table']
        ? data['table'].concat(deletedRows)
        : deletedRows;
    }
    if (!data['table']) {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage(
        'error',
        'PLEASE_ADD_THE_INFRASTRUCTURE_CONFIGURATIONS'
      );
      return;
    }
    data['table'].forEach((row) => {
      configs.push({
        proposalInfrastructureConfigId: row['proposalInfrastructureConfigId'],
        infraService: this.objectToIdConversionService.convertObjectToId(
          row['infraService']
        ),
        offerType: row['offerType']
          ? this.objectToIdConversionService.convertObjectToId(row['offerType'])
          : { id: null },
        reservationType: row['reservationType']
          ? this.objectToIdConversionService.convertObjectToId(
              row['reservationType']
            )
          : { id: null },
        customName: row['customName'],
        action: !row['proposalInfrastructureConfigId']
          ? 'CREATED'
          : row['action']
          ? row['action']
          : null,
        discountPercentage: row['discountPercentage'],
        marketplace: row['marketplace'],
      });
    });
    this.proposalInfraConfigControllerService
      .saveProposalInfraConfig({
        body: { proposalId: this.proposalId, infraConfig: configs },
      })
      .subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'INFRA_CONFIGURATION_UPDATE_SUCCESS'
          );
          saasPanel.open();
          this.onAccordionExpansion('saas-configuration');
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public updatePeripheralConfiguration(data: any, attachmentPanel) {
    let addButtonValidation;
    this.dynamicForm.createForm.value.peripheralConfig.forEach(data =>{
      if( data.peripheralService||data.discountPercentage) {
        addButtonValidation = true;
        this.responseHandlerService.returnToastMessage('error','PLEASE_ADD_THE_PERIPHERAL_CONFIGURATIONS');      
      }
    });
    if (addButtonValidation) {
      return null;
     }  
    this.showSpinner = this.loaderService.showProgressBar();
    const configs = [];
    const deletedRows = this.dynamicForm.dataSourceDeletedRows;
    if (deletedRows.length) {
      data['table'] = data['table']
        ? data['table'].concat(deletedRows)
        : deletedRows;
    }
    if (!data['table']) {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage(
        'error',
        'PLEASE_ADD_THE_PERIPHERAL_CONFIGURATIONS'
      );
      return;
    }
    data['table'].forEach((row) => {
      configs.push({
        proposalPeripheralConfigId: row['proposalPeripheralConfigId'],
        supportType: this.objectToIdConversionService.convertObjectToId(
          row['peripheralService']
        ),
        discountPercentage: row['discountPercentage']
          ? parseInt(row['discountPercentage'], 10)
          : 0,
        action: !row['proposalPeripheralConfigId']
          ? 'CREATED'
          : row['action']
          ? row['action']
          : null,
      });
    });
    this.proposalPeripheralConfigControllerService
      .saveProposalPeripheralConfig({
        body: { proposalId: this.proposalId, peripheralConfigs: configs },
      })
      .subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'PERIPHERAL_CONFIGURATION_UPDATE_SUCCESS'
          );
          attachmentPanel.open();
          this.onAccordionExpansion('attachments');
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  addActionColumn() {
    if (this.checkPermission('PROPSL_C')) {
      this.dynamicForm.columns.push('action');
    }
  }

  checkToDisableForm() {
    if (!this.checkPermission('PROPSL_C')) {
      this.disableSubmitButton = true;
      if (this.saasForm) {
        this.saasForm.disable();
      }
      if (this.dynamicForm) {
        this.dynamicForm.disableField = true;
        this.dynamicForm.hideTableActionButtons = true;
        if (this.dynamicForm.hasOwnProperty('createForm')) {
          this.dynamicForm.createForm.disable();
          const collection = Array.from(
            document.getElementsByClassName(
              'monthPicker-readonly'
            ) as HTMLCollectionOf<HTMLElement>
          );
          if (collection !== null && collection.length > 0) {
            collection.forEach((element) => {
              element.style.backgroundImage = 'none';
            });
          }
        }
      }
    }
  }

  resetForms() {
    this.proposalId = null;
    this.proposalList = null;
    this.dropDownValues['businessProfile'] = [];
    if (this.dynamicForm) {
      this.dynamicForm.createForm.reset();
      this.dynamicForm.dataSource = [];
    }
    if (this.saasForm) {
      this.saasForm.reset();
    }
  }

  public onClose() {
    this._matDialog.closeAll();
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.ignoreList.indexOf(item) <= -1);
    }
  }

  public openModal(content, proposalId) {
    this._matDialog.open(content);
    this.proposalId = proposalId;
  }

  public selectFiles(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.fileForUpload = event.target.files[0];
    }
  }

  public displayUploadedDocuments(proposalId) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.proposalControllerService
      .getProposalDocuments({ proposalId: proposalId })
      .subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          if (resp) {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.uploadedDocumentsList = [...resp];
            for (const data of this.uploadedDocumentsList) {
              data['uploadedOn'] = this.datePipe.transform(data['createdDate'], 'd-M-yyyy h:mm a');
              data['uploadedBy'] = data['createdBy'];
              data['action'] = null;
              delete data['createdDate'];
              delete data['createdBy'];
            }
            const dialogRef = this._matDialog.open(ShowHistoryDailogComponent, {
              maxWidth: '100%',
              data: {
                title: 'UPLOADED_DOCUMENTS',
                dataKey: this.uploadedDocumentsList,
              },
            });
            dialogRef.componentInstance.deleteItemEmitter.subscribe(
              (response) => {
                this.proposalControllerService
                  .deleteProposalDocuments({
                    proposalId: response['proposalId'],
                    id: response['id'],
                  })
                  .subscribe(
                    (apiresponse) => {
                      this.responseHandlerService.returnToastMessage(
                        'success',
                        'PROPOSAL_DOCUMENT_DELETED_SUCCESSFULLY'
                      );
                      dialogRef.close();
                    },
                    (err) => {
                      this.responseHandlerService.returnToastMessage(
                        'error',
                        err.error.message
                      );
                    }
                  );
              }
            );
          } else {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'warning',
              'NO_DATA_AVAILABLE'
            );
          }
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }
  public checkboxChange(skuId, productId?) {
    this.allSassServicelist.forEach((service) => {
      if (service.name == 'Notification Management') {
        const saaSServices = service.saaSServices;
        const targetNames = [
          'Notification - SMS',
          'Notification - SMS (Flat Rate)',
        ];
        const specificSkuIds = saaSServices
          .filter((item) => targetNames.includes(item.name)).map((item) => item.skuId);
        const atLeastOneSelected = specificSkuIds.some((id) => this.saasForm.get(id).value);
        if (specificSkuIds.includes(skuId)) {
          if (atLeastOneSelected) {
            specificSkuIds.forEach((id) => {
              if (this.saasForm.controls[id + '_discountPercentage'].value) {
                this.saasForm.controls[id + '_discountPercentage'].setValue('');
                this.saasForm.controls[id + '_discountPercentage'].disable();
              }
              if (id === skuId) {
                this.saasForm.get(id).setValue(true);
              } else {
                this.saasForm.get(id).setValue(false);
              }
            });
          } else {
            this.saasForm.get(skuId).setValue(this.saasForm.get(skuId).value);
          }
        }
      }
    });
    const mandatory: any = document.getElementsByClassName(skuId + 'id');
    [...mandatory].forEach((element) => {
      element.style.display = 'none';
    });
    const spanElement = document.getElementById(productId + 'id');
    if (this.saasForm.controls[skuId].value) {
      spanElement.style.display = 'initial';
      this.saasForm.controls[skuId + '_discountPercentage'].enable();
    } else {
      spanElement.style.display = 'none';
      this.saasForm.controls[skuId + '_discountPercentage'].disable();
      this.saasForm.controls[skuId + '_discountPercentage'].setValue('');
    }
  }

  public updateInfraConfigValidation(action = 'add') {
    this.dynamicForm.formBase.forEach((control) => {
      if (control.name === 'infraConfig') {
        control.fields.forEach((field) => {
          if (field.name === 'offerType' || field.name === 'reservationType') {
            if (action === 'add') {
              field.required = true;
              field.readonly = false;
            } else if (action === 'remove') {
              if (this.dynamicForm.createForm.controls['infraConfig']) {
                this.dynamicForm.createForm.controls['infraConfig'][
                  'controls'
                ].forEach((contr) => {
                  contr.controls[field.name].setErrors(null);
                });
              }
              field.required = false;
              field.readonly = true;
            }
          }
        });
      }
    });
  }

  // Marketplace checkbox is disable from UI side
  public updateMarketplace(action = 'disable') {
    if (action === 'enable') {
      this.dynamicForm.formBase.forEach((control) => {
        if (control.name === 'infraConfig') {
          control.fields.forEach((field) => {
            if (field.name === 'marketplace') {
              field.disabled = false;
            }
          });
        }
      });
    } else if (action === 'disable') {
      this.dynamicForm.formBase.forEach((control) => {
        if (control.name === 'infraConfig') {
          control.fields.forEach((field) => {
            if (field.name === 'marketplace') {
              field.disabled = true;
              field.value = false;
            }
          });
        }
      });
    }
  }

    public discountChange(event,id,skuId) {
      let inputValue = this.saasForm.controls[skuId+'_discountPercentage'].value;
      inputValue = inputValue.trim();
      const numericValue = inputValue.replace(/[^0-9.]/g, '');
      this.saasForm.controls[skuId+'_discountPercentage'].setValue(numericValue);
    this.currentProductId =id;
    const spanElement = document.getElementById(id);
    if(event.target.value){
      const mandatory:any = document.getElementsByClassName(skuId+'id');
      [...mandatory].forEach(element => {
        element.style.display='none';
       });
      const decimalRegex = /^\d+(\.\d{1,2})?$/;
         if(event.target.value > 100 || event.target.value <0 || (!decimalRegex.test(event.target.value))){
          spanElement.style.display = 'block';
         } else {
             spanElement.style.display = 'none';
         }
     }
   }

   public getAttachments() {
    this.uploadedFile = null;
    if(this.fileDropRef?.nativeElement?.value) {
      this.fileDropRef.nativeElement.value = '';
    }
    if(this.proposalId) {
      this.loadUploadedDocumentsAsTable(this.proposalId);
    }
    this.setPanel('attachments');
   }

   public uploadAttachments() {
    if(this.proposalId && this.uploadedFile) {
      this.showSpinner = this.loaderService.showProgressBar();
      const documentNames = [];
      const uploadedFiles = [];
    for (const file of this.uploadedFile) {
      uploadedFiles.push(file);
      documentNames.push(file.name); 
    }
      const params = {
        proposalId: this.proposalId,
        documentName: documentNames.join(','),
        body: {
          files: uploadedFiles
        },
      };
      this.proposalControllerService.uploadProposalDocuments(params).subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'FILE_UPLOAD_SUCCESS'
          );
          this.uploadedFile = null;
          if(this.fileDropRef?.nativeElement?.value) {
            this.fileDropRef.nativeElement.value = '';
          }
            this.loadUploadedDocumentsAsTable(this.proposalId);
          this.onClose();
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
    }
   }

   public uploadedDocumentsFilterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.uploadedDocumentsIgnoreList.indexOf(item) <= -1);
    }
  }

  public loadUploadedDocumentsAsTable(proposalId) {
    this.proposalControllerService.getProposalDocuments({ proposalId: proposalId }).subscribe(resp => {
      if (resp) {
        this.uploadedDocuments = resp;
        for (const data of this.uploadedDocuments) {
          data['uploadedOn'] = this.datePipe.transform(data['createdDate'], 'd-M-yyyy h:mm a');
          data['uploadedBy'] = data['createdBy'];
          data['action'] = null;
        }
        this.uploadedDocumentsDataSource = this.uploadedDocuments;
        this.uploadedDocumentsColumns = this.uploadedDocumentsFilterColumns(Object.keys(this.uploadedDocuments[0]));
      }
    },
      err => {
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
  }

  public gotoDeleteConfirmDialog(proposalId, id) {
    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
      maxWidth: '400px',
      disableClose: true, data: { message: this.translationService.translateErrorMessages('DELETE_DOCUMENT_MESSAGE') }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.showSpinner = this.loaderService.showProgressBar();
        this.proposalControllerService.deleteProposalDocuments({proposalId: proposalId, id: id})
        .subscribe( apiresponse => {
          this.responseHandlerService.returnToastMessage('success', 'PROPOSAL_DOCUMENT_DELETED_SUCCESSFULLY');
          this.loaderService.hideProgressBar(this.showSpinner);
          dialogRef.close();
          this.loadUploadedDocumentsAsTable(proposalId);
        },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        });
      } else {
        this._matDialog.closeAll();
      }
    });
  }

  public getTermsAndConditions() {
    this.saveTermsAndConditionsClicked =  false;
    this.termsAndConditionsControl = new FormControl('', [Validators.required,  Validators.pattern(/^(?!\s*$).+/)]);
    if(this.proposalId) {
      this.getProposalTerms();
    }
    this.setPanel('termsAndConditions');
  }

  public getProposalTerms() {
    this.proposalControllerService.getProposalTnc({ proposalId: this.proposalId }).subscribe(resp => {
      if (resp?.termsAndConditions) {
        this.termsAndConditionsControl.setValue(resp['termsAndConditions']);
        this.characterCount = resp['termsAndConditions'].length;
      }
    },
      err => {
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
  }

  public saveProposalTerms(tnc, proposalPanel) {
    this.showSpinner = this.loaderService.showProgressBar();
    const body = {
      proposalId: this.proposalId,
      tnc: tnc
    };
    this.proposalControllerService.saveProposalTnc({ proposalId: this.proposalId, body:body }).subscribe(resp => {
      if (resp) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', 'TERMS_AND_CONDITIONS_SAVED_SUCCESSFULLY');
        proposalPanel.open();
        this.onAccordionExpansion('proposal');
      }
    },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
  }

  public saveTermsAndConditions(proposalPanel) {
    this.saveTermsAndConditionsClicked = true;
    if(this.termsAndConditionsControl.valid && this.proposalId) {
      this.saveProposalTerms(this.termsAndConditionsControl.value, proposalPanel);
    }
  }

  public onTextInputChange() {
    this.characterCount = this.termsAndConditionsControl.value.length;
  }

  public onFileDropped(file) {
    if(file?.target?.files) {
      this.uploadedFile = file.target.files;
      this.uploadAttachments();
    }
  }

  public continueAfterAttachmentUpload(termsAndConditionsPanel) {

      const intervalId = setInterval(() => {
        if (termsAndConditionsPanel) {
          clearInterval(intervalId);
          termsAndConditionsPanel.open();
          this.onAccordionExpansion('termsAndCondition');
        }
      }, 1);

  }


    onInputBlur(event) {
        this.dynamicForm.formBase.forEach((control) => {
          if (control.name === 'proposedCredit') {
            control.fields.forEach((field) => {
              if ( field.name === 'fromDate' || field.name === 'toDate' || field.name === 'creditCap' ||field.name ==='creditCurrency') {
                field.required = event.event !== '';
              }
            });
          }
        });
      
    }

    navigateBack() {
      const proposal = { accountId:this.accountId, tenantId:this.tenantId,profileId:this.profileId };
      this.router.navigate(['billing/proposals'], { queryParams: { proposal: JSON.stringify(proposal) } });
    }

    public getPartnerCustomerId(channelPartnerId: string) {
      this.showSpinner = this.loaderService.showProgressBar();
      const params = {
        body: {
          channelPartnerId: channelPartnerId,
          tenantId: this.tenantId,
        },
      };
      this.mcpmssControllerService.getCustomerIdFromPartner(params).subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.dropDownValues['customerId'] = resp['id'];
          this.dynamicForm.formBase.forEach((control) => {
            if (control.name === 'customerId') {
              if (resp['id'] !== null) {
                this.dynamicForm['createForm']['controls']['customerId'].setValue(
                  resp['id']
                );
                control.readOnly = true;
              }
            }
          });
        },
        (err) => {
          this.dynamicForm.formBase.forEach((control) => {
            if (control.name === 'customerId') {
              control.readOnly = false;
            }});
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
    }

    public displaySaveToCreateNewProposal() {
      const dialogRef = this._matDialog.open(this.saveGeneralConfigContent, {
        maxHeight: '80vh',
        width: '570px',
        disableClose: true,
        data: null,
      });
    }
}

